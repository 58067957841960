<template>
  <div>
    <div class="header clearfix">
      <div class="pull-left">
        <img
          src="../../assets/images/home_img_Logo_nor.png"
          alt=""
          class="logo"
        />
      </div>
      <div class="pull-left">
        <span class="st">LEVC TX５指定出行服务运营商</span>
      </div>
      <div class="pull-right tab-img">
        <img
          v-if="showTab"
          src="../../assets/images/home_ic_table_nor.png"
          alt=""
          @click="showTab = false"
        />
        <img
          class="backNor"
          v-if="!showTab"
          src="../../assets/images/all_ic_back_nor.png"
          alt=""
          @click="showTab = true"
        />
      </div>
    </div>

    <div v-if="!showTab" class="tabContain">
      <ul>
        <li @click="changeIndex(0)"><a href="javascript:;">首页</a></li>
        <li @click="changeIndex(1)"><a href="javascript:;">预约出行</a></li>
        <li @click="changeIndex(2)"><a href="javascript:;">英伦座驾</a></li>
        <li @click="changeIndex(3)"><a href="javascript:;">品质服务</a></li>
        <li @click="changeIndex(4)"><a href="javascript:;">百年经典</a></li>
        <!-- <li @click="changeIndex(6)"><a href="javascript:;">政企服务</a></li> -->
        <li @click="changeIndex(5)"><a href="javascript:;">司机招募</a></li>
      </ul>
    </div>
    <el-container>
      <el-main>
        <section class="price">
         <h2>礼帽出行网约车运价组成包含以下项目</h2>
         <p>杭州市发展和改革委员会价格处、杭州市道路运输管理服务中心出租汽车处、杭州市交通运输行政执法队：
</p>
<p>结合我司目前的运力投放及同类服务或替代服务的定价情况，根据主管部门的相关规定，拟申请网络预约出租汽车服务价格调整备案，备案详细情况如下：</p>
    <h4>一、运价制定规则</h4>
    <p>1.1 运价制定考虑因素</p>
    <p>参考同类服务或替代服务定价，同时根据城市供需情况：如乘客活跃情况、司机供给数量、供需平衡情况等，观察到在低补贴情况下严重的供给不足，则会上调价格；观察到在低补贴情况下供给过剩，则会下调价格。

    </p>
    <p>1.2 停车费、车辆通行费等企业服务费代收垫付费用</p>
    <p>1.2.1按实际垫付金额收取，不受最终支付方式的影响。</p>
    <h4>二、专车运价确定机制</h4>
   <p>2.1 浙江礼帽出行科技有限公司拟投放一款LEVC TX5汽车用于专车出行服务</p>
    <p>2.2 专车出行运价组成包含以下项目</p>
   <p>2.2.1 时间计价项目包括：</p>
    <p>超时等候费</p>
    <p>2.2.2 里程计价项目包括：</p>
    <p>起步费、里程费、远途费</p>
    <p>2.2.3 取消费：</p>
    <p>轻责取消费、重责取消费</p>
  <p>2.2.4 预约用车模块：</p>
   <p>基础费（最低消费）：50元。</p>
    <p>自持管家服务费率：15%   （起步价+里程费+远途费）*15%</p>
    <p>指定管家费：30元</p>
    <p>2.2.5 停车费、车辆通行费等企业服务费代收垫付费用</p>
   <p> 按实际垫付金额收取，不受最终支付方式的影响</p>
    <p>2.2.6 无夜间费、时长费、低速行驶费等其他费用</p>
   <p>2.3 专车项目计价方式</p>
    <p>采用时间计价、里程计价、取消费相加，预约用车基础价50元、自持管家服务费率15%。</p>
    
    <h4>三、礼帽出行APP网约车运价方案</h4>
   <p>3.1 礼帽出行网约车预约订单运价将以起步价、里程费、远途费、取消费、超时等候费和预约用车模块构成。</p>
   <p>礼帽APP定价-预约</p>
    <p>起步价</p>                                                    
    <p>普通时段（2.0公里）	15.50元</p>
    <p>里程费</p>
    <p>普通时段（1.0公里）	2.80元</p>
    <p>00:00-05:00	3.80元/公里</p>
    <p>05:00-07:00	2.80元/公里</p>
    <h4>收费公示制度</h4>
    <p>为规范收费行为，完善监督管理措施，增加透明度，防止乱收费，我平台实行收费公示。</p>
        <p>1、平台通过手机APP（确认呼叫页-预估价-费用明细-计价规则；[待支付、待评价、已完成]订单详情页-费用-费用明细-计价规则），向驾驶员和乘客公布运价组成、计价方式、价格标准及加价幅度等相关内容，便于社会及各监管部门监督平台是严格执行定价标准，保护各位驾驶员及乘客自身合法权益。</p>
            <p>2、收费公示应根据实际情况及动态管理、确保及时更新、保证司机及乘客的知情权。</p>
                <p>3、 收费公示接受消费者和社会各界的监督，如有问题可致电相关监督电话：0571-87761580。</p>
        </section>
        <!-- <Footer /> -->
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import Footer from "./footer.vue";
export default {
  components: {
    // Footer,
  },
  data() {
    return {
      showTab: true,
    };
  },
  computed: {
    ...mapState({
      homeIndex: (store) => store.common.homeIndex,
    }),
  },
  mounted() {
    this.$store.commit("loading/HIDELOADING");
  },
  methods: {
    changeIndex(index) {
      this.$store.commit("common/HOMEINDEX", index);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 2.875rem;
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.header .logo {
  display: inline-block;
  width: 88px;
  height: 1.375rem;
}
.header .st {
  color: #222222;
  font-size: 0.75rem;
  line-height: 1.5625rem;
  padding-left: 1.0625rem;
}
.header .tab-img img {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
}

.backNor {
  animation: dropdown 0.5s 1;
}
@keyframes dropdown {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

.tabContain {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 2.8rem;
  overflow-y: auto;
  background-color: #fff;
  padding-top: 3.125rem;
  z-index: 999;
}

@keyframes showBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tabContain ul {
  margin-left: 1rem;
  animation: showBar 1s 1;
}
.tabContain ul li {
  padding: 1.375rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  text-align: left;
}
.el-container {
  background: #fff;
  // height: 100vh;
  color: #222;
  .el-header {
    padding: 15px 60px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 0px auto;
    background: #ffffff;
    font-size: 14px;
    min-width: 1200px;
    height: 60px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 999;
    .header-left {
      img {
        display: inline-block;
        height: 30px;
        vertical-align: middle;
        display: inline-block;
        padding-right: 10px;
      }
      span {
        vertical-align: middle;
        border-left: 1px solid #e6e6e6;
        display: inline-block;
        padding-left: 10px;
        font-family: PingFangSC-Light;
        border-radius: 1px;
      }
    }
    ul {
      li {
        float: left;
        a {
          margin-left: 70px;
          display: inline-block;
          line-height: 30px;
          font-family: PingFangSC-Regular;
          position: relative;
          height: 32.5px;
        }
        a:after {
          content: " ";
          position: absolute;
          z-index: 2;
          bottom: 0px;
          left: 50%;
          display: block;
          width: 18px;
          height: 1px;
          transform: translate(-50%);
        }
        a:hover:after {
          height: 2px;
          animation: ad_width 0.1s linear forwards;
          background: #181818;
        }
        .line {
          box-sizing: border-box;
          line-height: 10px;
          display: block;
          width: 18px;
          height: 2.5px;
          border: 1px solid #181818;
          border-radius: 20%;
          background: #181818;
          margin: 0 auto;
        }
        @keyframes ad_width {
          from {
            width: 0;
          }

          to {
            width: 18px;
          }
        }
      }
    }
  }
  .el-main {
    // height: calc(100vh - 60px);
    overflow-x: hidden;
    padding: 0;
    // margin-top: 60px;
    width: 100%;
    #infomation7 {
      height: 890px;
      margin-top: 60px;
      background: url("../../assets/images/server-home.png") no-repeat;
      background-size: cover;
      position: relative;
    }
    #infomation7 .server-box {
      position: absolute;
      right: 160px;
      top: 253px;
      width: 420px;
      height: 384px;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      text-align: center;
      overflow: hidden;
      padding: 48px 60px 30px;
      box-sizing: border-box;
    }

    #infomation7 .server-box .title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      padding-bottom: 0;
    }
    ::v-deep .server-box {
      .el-input {
        margin-top: 20px;
        .el-input__inner {
          color: #fff;
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          background-color: inherit;
          text-align: center !important;
        }
      }
    }
    #infomation7 .button-box {
      .el-button {
        margin-top: 50px;
        width: 100%;
        background: #f9d230;
        border-radius: 6px;
        border-color: #f9d230;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #17191e;
      }
    }
    .server-btitle {
      padding-top: 100px;
      text-align: center;
      font-size: 40px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 44px;
    }

    .server-stitle {
      padding-top: 24px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 24px;
    }
    .server-flex-box {
      padding: 71px 179px 124px;
      margin-left: -40px;
      margin-right: -40px;
      width: calc(100% + 80px);
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(50% - 40px);
        .img-box {
          // height: 452px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
        .flex-child-title {
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
          text-align: left;
          padding-top: 23px;
        }
      }
    }
    .yl-box {
      background-color: #f8f8f8;
    }
    .server-flex-yl {
      padding: 71px 179px 124px;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(33% - 30px);
        .img-box {
          // height: 430px;
          img {
            width: 100%;
            // height: 430px;
            display: inline-block;
          }
        }
        .yl-child-title {
          padding-top: 23px;
          text-align: left;
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
        }
        .yl-child-title2 {
          padding-top: 16px;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 20px;
        }
      }
    }

    .first-box,
    .six-box {
      width: 100%;
      img {
        display: inline-block;
        width: 100%;
      }
    }
    .first-box {
      .kong {
        height: 60px;
      }
    }
    .forth-box {
      font-family: PingFangSC-Light;
      height: 890px;
      background: url("../../assets/images/bg.png") no-repeat;
      background-size: cover;
      .name {
        font-size: 40px;
        color: #222222;
        padding: 100px 0 120px 0;
      }
      .rel-box {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        img {
          display: inline-block;
          width: 873px;
          height: 440px;
        }
        span {
          font-family: PingFangSC-Regular;
          color: #222;
        }
        .sp1 {
          position: absolute;
          top: 0;
          left: 40px;
        }
        .sp2 {
          position: absolute;
          top: -30px;
          left: 265px;
        }
        .sp3 {
          position: absolute;
          bottom: 152px;
          left: -135px;
        }
        .sp4 {
          position: absolute;
          bottom: -25px;
          left: 187px;
        }
      }
    }
    .second {
      background: #f8f8f8;
      padding: 95px 60px;
      min-width: 1200px;
    }

    .second-box {
      margin: 0 auto;
      .img-box {
        width: 46%;
        img {
          display: inline-block;
          width: 100%;
          //   height: 700px;
        }
      }
      .logo-box {
        padding-left: 60px;
        width: 54%;
        .info-box1 {
          font-family: PingFangSC-Regular;
          margin-top: 60px;
          font-size: 24px;
          color: #222222;
          text-align: left;
          line-height: 50px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        @media only screen and (min-width: 1281px) {
          .info-box1 {
            font-size: 32px;
          }
        }
        .info-box2 {
          font-size: 12px;
          color: #222222;
          text-align: left;
          line-height: 30px;
          margin-top: 40px;
          margin-bottom: 40px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
          font-family: PingFangSC-Regular;
        }
        @media only screen and (min-width: 1281px) {
          .info-box2 {
            font-size: 18px;
          }
        }
        .img-box2 {
          text-align: center;
          img {
            display: inline-block;
            width: 240px;
            height: 220px;
            //   height: 200px;
          }
        }
        @media only screen and (min-width: 1440px) {
          img {
            margin-top: 0px;
          }
        }
        @media only screen and (min-width: 1680px) {
          img {
            margin-top: 70px;
          }
        }
      }
    }

    .third-box {
      background: #ffffff;

      .title {
        font-family: PingFangSC-Light;
        padding: 100px 0 120px 0;
        text-align: center;
        color: #222222;
        font-size: 40px;
      }
    }
    .five-box {
      background: #f8f8f8;
      padding-bottom: 120px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 44px;
        padding-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .img-box {
        display: flex;
        padding: 0 190px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        .five-img-box {
          width: calc(33% - 30px);
          margin: 15px;
          .simg-box {
            width: 100%;
            img {
              display: inline-block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 24px;
            margin-top: 24px;
            font-family: PingFang-SC-Medium;
          }
          .stitle {
            font-family: PingFangSC-Light;
            text-align: left;
            font-size: 16px;
            margin-top: 16px;
          }
        }
      }
    }
    .swiper-box {
      display: flex;
      padding: 0 190px 120px 190px;
      justify-content: space-between;
    }
    .swiper-container {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      .banner-swiper-item {
        transform: translate3d(0, 0, 0);
        border-radius: 4px;
        // width: 100%;
        width: calc(33% - 30px);
        height: 100%;
        overflow: hidden;
        display: block;
        position: relative;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        .swiper-info {
          text-align: left;
          font-size: 24px;
          color: #222222;
          font-family: PingFang-SC-Medium;
          margin-top: 24px;
        }
        .swiper-sinfo {
          text-align: left;
          font-size: 16px;
          color: #222222;
          font-family: PingFangSC-Light;
          margin-top: 16px;
          line-height: 16px;
        }
      }
    }
    .seven-box {
      padding-top: 100px;
      background: #fff;
      padding-bottom: 139px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 40px;
        // margin-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .seven-img-box {
        display: flex;
        padding: 0 60px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        min-width: 1200px;
        .img-box {
          width: calc(25% - 30px);
          margin: 15px;
          background: #f1f1f1;
          .simg-box {
            width: 100%;
            img {
              display: inline-block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 18px;
            margin-top: 10px;
            padding-left: 13px;
            // padding-bottom: 13px;
            font-family: PingFangSC-Regular;
          }
          .stitle {
            padding: 0 13px 13px 13px;
            text-align: left;
            font-size: 14px;
            margin-top: 8px;
            line-height: 16px;
            font-family: PingFangSC-Light;
          }
        }
      }
    }
    .nine-box {
      padding-top: 100px;
    }
    .manager-box {
      width: 52%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-left: 117px;
      .title {
        font-family: PingFangSC-Light;
        padding-bottom: 100px;
        font-size: 40px;
        color: #222222;
      }
      .input-box {
        // margin-left: 93px;
        // width: 360px;
        margin-bottom: 30px;
        .el-input {
          width: 360px;
          .el-input__inner {
            height: 50px;
            width: 360px;
            font-family: PingFang-SC-Medium;
          }
        }
      }
      .button-box {
        padding-bottom: 136px;
        .el-button {
          background: #181818;
          width: 160px;
          border-color: #181818;
        }
      }
    }
    .eight-box {
      display: flex;
      min-width: 1200px;
      .left-box {
        width: 48%;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .right-box {
        width: 52%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-left: 117px;
        background: #f8f8f8;
        padding-top: 60px;
        .title {
          font-family: PingFangSC-Light;
          padding-bottom: 100px;
          font-size: 40px;
          color: #222222;
        }
        .input-box {
          // margin-left: 93px;
          // width: 360px;
          margin-bottom: 30px;
          .el-input {
            width: 360px;
            .el-input__inner {
              height: 50px;
              width: 360px;
              font-family: PingFang-SC-Medium;
            }
          }
        }
        .button-box {
          padding-bottom: 136px;
          .el-button {
            background: #181818;
            width: 160px;
            border-color: #181818;
          }
        }
      }
    }

    .footer {
      font-size: 14px;
      margin-top: -4px;
      background: #333;
      padding: 55px 100px 0 100px;
      min-width: 1200px;
      color: #fff;
      .pull-left {
        padding-right: 144px;
      }
      .name {
        text-align: left;
        font-size: 16px;
        margin-bottom: 30px;
        font-family: PingFangSC-Regular;
      }
      ul {
        padding-bottom: 20px;
        li {
          text-align: left;
          line-height: 18px;
          padding-bottom: 12px;
          a {
            color: #fff;
            font-family: PingFangSC-Light;
          }
          a:hover {
            color: #f9d230;
          }
        }
      }
      .box1 {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
      .box2 {
        padding: 30px 0;
        font-size: 16px;
        font-family: PingFangSC-Light;
        a {
          color: #fff;
        }
      }
    }
  }
}
.price {
  padding: 16px;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
}
.price h2 {
  height: 26px;
  font-size: 22px;
  text-align: center;
  margin: 0 0 16px;
}
.price h4 {
  margin: 10px 0 0;
  font-size: 14px;
  text-align: left;
}
.price h5 {
  margin: 10px 0 0;
  font-size: 14px;
  text-align: left;
}
.price p {
  text-indent: 2em;
}
table {
  text-align: center;
  font-size: 12px;
  width: 100%;
}
table tr td {
  font-size: 14px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
}
table tr td:last-child {
  border-right: 1px solid #999;
}
table tr:last-child td {
  border-bottom: 1px solid #999;
}
</style>
